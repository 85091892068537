import useAxios from "axios-hooks";

const useRegister = () => {
  const [
    { loading: registering, error: registerError, data: registerData },
    register,
  ] = useAxios(
    {
      url: "/register",
      method: "POST",
    },
    { manual: true },
  );

  const handleRegister = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string,
  ) => {
    try {
      await register({
        data: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email,
          password,
          password_confirmation: confirmPassword,
        }),
      });

      console.debug("Registered successfully:", registerData);
      return { success: true, data: registerData };
    } catch (error) {
      console.error("Registration failed:", error);
      return { success: false, error: registerError };
    }
  };

  return { handleRegister, registering, registerError, registerData };
};

export default useRegister;
