import useAxios from "axios-hooks";

const useLogin = () => {
  const [{ loading: loggingIn, error: loginError, data: loginData }, login] =
    useAxios(
      {
        url: "/login",
        method: "POST",
      },
      { manual: true },
    );

  const handleLogin = async (emailOrPhone: string, password: string) => {
    try {
      await login({ data: JSON.stringify({ email: emailOrPhone, password }) });
      console.debug("Logged in successfully:", loginData);
      return { success: true, data: loginData };
    } catch (error) {
      console.error("Login failed:", error);
      return { success: false, error: loginError };
    }
  };

  return { handleLogin, loggingIn, loginError, loginData };
};

export default useLogin;
