import React, { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  Divider,
  FormControl,
  OutlinedInput,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ProvideButton from "../Buttons/ProvideButton";
import DismissibleBox from "../DismissibleBox/DismissibleBox";
import ModalContainer from "../Modal/ModalContainer";
import VerifyCodeDialogue from "../Modal/VerifyCodeDialogue";
import useValidateInput from "../../hooks/useValidateInput";
import useSendOTPCode from "../../hooks/useSendOTPCode";
import useAuthenticateOTPCode from "../../hooks/useAuthenticateOTPCode";
import useLogin from "../../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useRedux";
import { login } from "../../store/slices/authSlice";

interface PasswordInputProps {
  emailOrPhone: string;
  onBack: () => void;
}

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const { emailOrPhone, onBack } = props;

  const [password, setPassword] = useState<string>("");
  const [otpStatus, setOtpStatus] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean | undefined>(false);

  const { validateField } = useValidateInput();
  const { handleSendOTPCode } = useSendOTPCode();
  const { handleAuthenticateOTPCode } = useAuthenticateOTPCode();
  const { handleLogin, loggingIn, loginError } = useLogin();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isLoginDisabled = !password.trim();

  const { heading, formControl, button, divider, forgottenText, modal } =
    theme.custom.passwordInput;

  useEffect(() => {
    const emailValid = validateField("email", emailOrPhone);
    setIsEmailValid(emailValid);
  }, [emailOrPhone]);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
    if (!isModalOpen) {
      handleSendOTPCode(emailOrPhone).then(() => {
        setDataLoaded(true);
      });
    }
  };

  const dispatchThenNavigate = () => {
    dispatch(login());
    navigate("/dashboard");
  };

  const onSubmit = async () => {
    const result = await handleLogin(emailOrPhone, password);

    if (result.success) {
      dispatchThenNavigate();
    }
  };

  const onEnterOTPCode = async (code: string) => {
    const otp = await handleAuthenticateOTPCode(code);

    if (otp.success) {
      toggleModal();
      dispatchThenNavigate();
    } else {
      setOtpStatus("Invalid Code");
    }
  };

  return (
    <>
      <Typography variant="loginH4Text" component="h4" sx={heading}>
        Password
      </Typography>

      <DismissibleBox emailOrPhone={emailOrPhone} onBack={onBack} />

      <FormControl sx={formControl} variant="outlined">
        <OutlinedInput
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          placeholder="Password"
        />
      </FormControl>

      <ProvideButton
        onClick={onSubmit}
        variant="contained"
        disabled={isLoginDisabled || loggingIn}
        sx={button}
      >
        {loggingIn ? "Logging in..." : "LOGIN"}
      </ProvideButton>

      {loginError && (
        <Typography color="error" mt={1}>
          {loginError.message}
        </Typography>
      )}

      <Divider sx={divider} />
      <Typography variant="body2" sx={forgottenText}>
        Forgotten your password / Use One-time Password
      </Typography>

      <ProvideButton
        onClick={toggleModal}
        variant="outlined"
        sx={button}
        disabled={!isEmailValid}
      >
        EMAIL LOGIN LINK
      </ProvideButton>

      <ModalContainer isOpen={isModalOpen} sx={modal}>
        <VerifyCodeDialogue
          title={dataLoaded ? "Link Sent" : "Sending Login Link to:"}
          description={emailOrPhone}
          onConfirm={onEnterOTPCode}
          onClose={toggleModal}
          numberOfBoxes={6}
          statusMessage={otpStatus}
        />
      </ModalContainer>
    </>
  );
};

export default PasswordInput;
