import React from "react";
import RootScreen from "./RootScreen";

interface DashboardScreenProps {
  infoText: string;
}

const DashboardScreen: React.FC<DashboardScreenProps> = ({ infoText }) => {
  return <RootScreen>{infoText}</RootScreen>;
};

export default DashboardScreen;
