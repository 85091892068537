import React from "react";
import { Outlet, useMatch } from "react-router-dom";
import { AppBar, Box, Typography, Container, useTheme } from "@mui/material";
import ProvideLogoText from "../Icons/ProvideLogo/ProvideLogoText";
import { loginData, pillData } from "./data/menuData";
import ProvideLogo from "../Icons/ProvideLogo/ProvideLogo";
import PillNavigation from "../PillNavigation/PillNavigation";

const LoginSignupToolbar: React.FC = () => {
  const theme = useTheme();
  const isRoot = useMatch("/");

  const { appBar, container, pillContainer, logoBox, mobileLogoBox, menuBox } =
    theme.custom.loginSignupToolbar;

  const defaultTheme = JSON.stringify({
    name: "Provide",
    primary_color: null,
    secondary_color: null,
    tertiary_color: null,
    logo: null,
    t_name: "Provide Finance",
  });
  const companyTheme = JSON.parse(
    localStorage.getItem("PF-CONF") || defaultTheme,
  );

  return (
    <>
      <AppBar sx={appBar}>
        <Container sx={isRoot ? container : pillContainer}>
          <Box sx={logoBox}>
            {companyTheme.logo ? (
              <img
                src={companyTheme.logo}
                alt={companyTheme.name}
                width={212}
                height={44}
              />
            ) : (
              <ProvideLogoText width={212} height={44} />
            )}
          </Box>
          <Box sx={mobileLogoBox}>
            {companyTheme.logo ? (
              <img
                src={companyTheme.logo}
                alt={companyTheme.name}
                width={44}
                height={45}
              />
            ) : (
              <ProvideLogo width={44} height={45} />
            )}
          </Box>
          {isRoot ? (
            <Box sx={menuBox}>
              {loginData.map((item) => (
                <Typography key={item.id} component="h2" variant={item.variant}>
                  {item.text}
                </Typography>
              ))}
            </Box>
          ) : (
            <PillNavigation buttons={pillData} />
          )}
        </Container>
      </AppBar>
      <Outlet />
    </>
  );
};

export default LoginSignupToolbar;
