import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import LoginWave from "../components/Icons/LoginWave/LoginWave";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { RootState } from "../store/store";
import DisclaimerText from "../components/DisclamerText/DisclamerText";
import DropdownInfoBox from "../components/DropDownInfoBox/DropDownInfoBox";
import DropShadowBox from "../components/DropShadowBox/DropShadowBox";
import SignUpBorrower from "../components/SignUpBorrower/SignUpBorrower";
import { checkUrlForSignUpType } from "../store/slices/signupSlice";
import { useTheme } from "@mui/material/styles";

const SignUpScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(checkUrlForSignUpType());
  }, [dispatch]);

  const userType = useAppSelector((state: RootState) => state.signup.userType);

  const { loginWave, container, subtitle, dropShadowBox, createAccountText } =
    theme.custom.signUpScreen;

  return (
    <>
      <Box sx={loginWave}>
        <LoginWave />
      </Box>
      <Container>
        <Box sx={container}>
          <Typography component="h1" variant="loginH1Text">
            Create a Free Account
          </Typography>
          <Typography component="h2" variant="signupH2Text" sx={subtitle}>
            Lets gets started. Are you ready to be a part of something new?
          </Typography>
          <Box sx={dropShadowBox}>
            <DropShadowBox>
              {userType === "borrower" ? (
                <SignUpBorrower />
              ) : (
                <div>Broker SignUp</div>
              )}
            </DropShadowBox>
          </Box>
          <Typography sx={createAccountText}>
            <span>
              Want to create a{" "}
              <span>
                <Link to="./" style={{ fontWeight: "bold" }}>
                  Broker
                </Link>
              </span>{" "}
              account instead?
            </span>{" "}
          </Typography>
          <DropdownInfoBox />
          <DisclaimerText />
        </Box>
      </Container>
    </>
  );
};

export default SignUpScreen;
