import React from "react";
import { Box, useTheme } from "@mui/material";
import { useOutletContext } from "react-router-dom";

interface RootScreenProps {
  children: React.ReactNode;
}

const RootScreen: React.FC<RootScreenProps> = ({ children }) => {
  const { drawerOpen } = useOutletContext<{ drawerOpen: boolean }>();
  const theme = useTheme();
  const { menuOpenWidth, menuClosedWidth } = theme.custom.contentWidth;

  return (
    <Box
      sx={{
        paddingLeft: drawerOpen ? menuOpenWidth : menuClosedWidth,
      }}
    >
      {children}
    </Box>
  );
};

export default RootScreen;
