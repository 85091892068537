import React from "react";

interface ProvideLogoProps {
  width?: number;
  height?: number;
  fillColor?: string;
  padding?: string;
}

const ProvideLogo: React.FC<ProvideLogoProps> = (props) => {
  const {
    width = 67,
    height = 68,
    fillColor = "#000000",
    padding = "0",
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 67 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ shapeRendering: "auto", padding }}
    >
      <path
        d="M64.4384 33.4555L57.5726 13.7441C51.7826 -2.86667 28.8123 -4.25881 21.0606 11.5609L11.8852 30.2915C7.42399 39.4037 -15.4197 74.3337 17.4221 66.582L45.2016 60.001C70.6397 54.0212 67.7605 43.0106 64.4384 33.4555ZM27.9897 36.0499C24.2878 36.0499 21.3137 33.0442 21.3137 29.374C21.3137 25.7038 24.3195 22.6981 27.9897 22.6981C31.6598 22.6981 34.6656 25.7038 34.6656 29.374C34.6656 33.0442 31.6915 36.0499 27.9897 36.0499ZM47.0367 36.0499C43.3348 36.0499 40.3607 33.0442 40.3607 29.374C40.3607 25.7038 43.3665 22.6981 47.0367 22.6981C50.7068 22.6981 53.7126 25.7038 53.7126 29.374C53.7126 33.0442 50.7068 36.0499 47.0367 36.0499Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ProvideLogo;
