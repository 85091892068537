import React, { useRef, useState } from "react";
import { Typography, Box, TextField, useTheme } from "@mui/material";
import DropShadowBox from "../DropShadowBox/DropShadowBox";
import PlainTextButton from "../Buttons/PlainTextButton";

export interface VerifyCodeDialogueProps {
  title: string;
  description: string;
  statusMessage?: string;
  onConfirm: (code: string) => void;
  onClose: () => void;
  numberOfBoxes: number;
}

const VerifyCodeDialogue: React.FC<VerifyCodeDialogueProps> = (props) => {
  const {
    title,
    description,
    onConfirm,
    onClose,
    numberOfBoxes,
    statusMessage,
  } = props;

  const [code, setCode] = useState<string[]>(Array(numberOfBoxes).fill(""));
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const theme = useTheme();

  const {
    textAlign,
    titleStyle,
    descriptionStyle,
    enterCode,
    inputContainer,
    inputField,
    statusMessageStyle,
    closeButton,
  } = theme.custom.verifyCode;

  const updateCode = (newCode: string[], index: number) => {
    setCode(newCode);

    const isComplete = !newCode.includes("");

    if (isComplete) {
      onConfirm(newCode.join(""));
    } else if (index < numberOfBoxes - 1 && newCode[index]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      /*
        This ensures that only a single digit or no digit is allowed in the input.
        
      */
      if (/^\d?$/.test(value)) {
        const updatedCode = [...code];
        updatedCode[index] = value;
        updateCode(updatedCode, index);
      }
    };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    /*
      regex returns true if the string digit contains at least
      one numeric character (0-9), and false otherwise.
    
    */
    const pasteData = event.clipboardData
      .getData("Text")
      .slice(0, numberOfBoxes)
      .split("")
      .map((digit) => (/\d/.test(digit) ? digit : ""));

    const completePasteData = [
      ...pasteData,
      ...Array(numberOfBoxes - pasteData.length).fill(""),
    ];

    setCode(completePasteData);

    completePasteData.forEach((digit, idx) => {
      if (inputRefs.current[idx]) {
        inputRefs.current[idx]!.value = digit;
      }
    });

    updateCode(completePasteData, completePasteData.length - 1);
    event.preventDefault();
  };

  const handleKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Backspace" && !code[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    };

  return (
    <DropShadowBox>
      <Box sx={{ textAlign }}>
        <Typography component="h4" variant="loginH4Text" sx={titleStyle}>
          {title}
        </Typography>
        <Typography component="p" variant="body1" sx={descriptionStyle}>
          {description}
        </Typography>
      </Box>

      <Typography component="p" variant="body2" sx={enterCode}>
        Enter Code
      </Typography>
      <Box sx={inputContainer}>
        {code.map((digit, index) => (
          <TextField
            key={index}
            value={digit}
            onChange={handleChange(index)}
            onKeyDown={handleKeyDown(index)}
            inputRef={(el) => (inputRefs.current[index] = el)}
            slotProps={{
              htmlInput: {
                maxLength: 1,
                style: { textAlign: "center" },
                onPaste: handlePaste,
              },
            }}
            sx={inputField}
          />
        ))}
      </Box>
      <Typography component="p" variant="body2" sx={statusMessageStyle}>
        {statusMessage}
      </Typography>
      <PlainTextButton sx={closeButton} onClick={onClose}>
        CLOSE
      </PlainTextButton>
    </DropShadowBox>
  );
};

export default VerifyCodeDialogue;
