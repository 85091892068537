import useAxios from "axios-hooks";

const useAuthenticateOTPCode = () => {
  const [{ error: OTPError, data: OTPData }, authenticateOTPCode] = useAxios(
    {
      url: "/authenticate-otp",
      method: "POST",
    },
    { manual: true },
  );

  const handleAuthenticateOTPCode = async (token: string) => {
    try {
      await authenticateOTPCode({
        data: JSON.stringify({ token }),
      });
      console.debug("Authenticated code successfully:", OTPData);
      return { success: true, data: OTPData };
    } catch (error) {
      console.error("Error authenticating code:", error);
      return { success: false, error: OTPError };
    }
  };

  return { handleAuthenticateOTPCode, OTPError, OTPData };
};

export default useAuthenticateOTPCode;
