import { CSSObject } from "@mui/system";
import { createTheme, Theme } from "@mui/material/styles";
import { CSSProperties } from "react";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    loginHeaderTextActive: CSSProperties;
    loginHeaderTextInactive: CSSProperties;
    loginH1Text: CSSProperties;
    loginH4Text: CSSProperties;
    signupH2Text: CSSProperties;
    loginCreateAccountText: CSSProperties;
    loginParagraphText: CSSProperties;
    drawerHeading: CSSProperties;
    drawerText: CSSProperties;
  }

  interface TypographyVariantsOptions {
    loginHeaderTextActive?: CSSProperties;
    loginHeaderTextInactive?: CSSProperties;
    loginH1Text?: CSSProperties;
    loginH4Text?: CSSProperties;
    signupH2Text?: CSSProperties;
    loginCreateAccountText?: CSSProperties;
    loginParagraphText?: CSSProperties;
    drawerHeading?: CSSProperties;
    drawerText?: CSSProperties;
  }

  interface Theme {
    custom: {
      grayDisclamerText: string;
      greenConfirmText: string;
      grayLinkText: string;
      basePurple: string;
      contentWidth: {
        menuClosedWidth: string;
        menuOpenWidth: string;
      };
      authWrapper: {
        box: CSSObject;
        circularProgress: CSSObject;
      };
      emailPhoneInput: {
        provideButtonOutlined: CSSObject;
        iconBox: CSSObject;
        typography: CSSObject;
        formControl: CSSObject;
        outlinedInput: CSSObject;
        continueButton: CSSObject;
      };
      pillNavigation: {
        box: CSSObject;
        button: CSSObject;
        activeButton: CSSObject;
        inactiveButton: CSSObject;
      };
      signUpScreen: {
        container: CSSObject;
        subtitle: CSSObject;
        dropShadowBox: CSSObject;
        createAccountText: CSSObject;
        loginWave: CSSObject;
      };
      loginScreen: {
        loginWave: CSSObject;
        container: CSSObject;
        loginComponentBox: CSSObject;
        createAccountText: CSSObject;
      };
      signUpBorrower: {
        flexContainer: CSSObject;
        inputMargin: CSSObject;
        formInput: CSSObject;
        checkBox: CSSObject;
        labelTypography: CSSObject;
        radioButton: CSSObject;
        radioGroup: CSSObject;
        submitButton: CSSObject;
        divider: CSSObject;
        registerText: CSSObject;
        iconContainer: CSSObject;
        iconMargin: CSSObject;
      };
      dropShadowBox: {
        p: number;
        backgroundColor: string;
        boxShadow: string;
        border: number;
        borderRadius: number;
        borderColor: string;
      };
      dismissibleBox: {
        bgcolor: string;
        py: number;
        px: number;
        borderRadius: number;
        mb: number;
      };
      loginSignupToolbar: {
        appBar: CSSObject;
        container: CSSObject;
        pillContainer: CSSObject;
        logoBox: CSSObject;
        mobileLogoBox: CSSObject;
        menuBox: CSSObject;
      };
      navigationRail: {
        container: CSSObject;
        logoBox: CSSObject;
        iconButton: CSSObject;
        activeBackgroundColor: string;
        inactiveBackgroundColor: string;
        listItem: CSSObject;
        text: CSSObject;
        notificationIcon: CSSObject;
        avatar: CSSObject;
        bottomSection: CSSObject;
        logoMarginBottom: CSSObject;
        iconButtonGray: CSSObject;
      };
      navigationDrawer: {
        box: CSSObject;
        paper: CSSObject;
        listItem: CSSObject;
        subListItem: CSSObject;
        subListItemInnerPadding: CSSObject;
        text: CSSObject;
        headingStyle: CSSObject;
      };
      passwordInput: {
        heading: CSSObject;
        formControl: CSSObject;
        button: CSSObject;
        divider: CSSObject;
        forgottenText: CSSObject;
        modal: CSSObject;
      };
      verifyCode: {
        textAlign: string;
        titleStyle: CSSObject;
        descriptionStyle: CSSObject;
        enterCode: CSSObject;
        inputContainer: CSSObject;
        inputField: CSSObject;
        statusMessageStyle: CSSObject;
        closeButton: CSSObject;
      };
    };
  }

  interface ThemeOptions {
    custom?: {
      grayDisclamerText?: string;
      greenConfirmText?: string;
      grayLinkText?: string;
      basePurple?: string;
      contentWidth: {
        menuClosedWidth: string;
        menuOpenWidth: string;
      };
      authWrapper: {
        box: CSSObject;
        circularProgress: CSSObject;
      };
      emailPhoneInput: {
        provideButtonOutlined: CSSObject;
        iconBox: CSSObject;
        typography: CSSObject;
        formControl: CSSObject;
        outlinedInput: CSSObject;
        continueButton: CSSObject;
      };
      pillNavigation: {
        box: CSSObject;
        button: CSSObject;
        activeButton: CSSObject;
        inactiveButton: CSSObject;
      };
      signUpScreen: {
        container: CSSObject;
        subtitle: CSSObject;
        dropShadowBox: CSSObject;
        createAccountText: CSSObject;
        loginWave: CSSObject;
      };
      loginScreen: {
        loginWave: CSSObject;
        container: CSSObject;
        loginComponentBox: CSSObject;
        createAccountText: CSSObject;
      };
      signUpBorrower: {
        flexContainer: CSSObject;
        inputMargin: CSSObject;
        formInput: CSSObject;
        checkBox: CSSObject;
        labelTypography: CSSObject;
        radioButton: CSSObject;
        radioGroup: CSSObject;
        submitButton: CSSObject;
        divider: CSSObject;
        registerText: CSSObject;
        iconContainer: CSSObject;
        iconMargin: CSSObject;
      };
      dropShadowBox?: {
        p?: number;
        backgroundColor?: string;
        boxShadow?: string;
        border?: number;
        borderRadius?: number;
        borderColor?: string;
      };
      dismissibleBox?: {
        bgcolor?: string;
        py?: number;
        px?: number;
        borderRadius?: number;
        mb?: number;
      };
      loginSignupToolbar: {
        appBar: CSSObject;
        container: CSSObject;
        pillContainer: CSSObject;
        logoBox: CSSObject;
        mobileLogoBox: CSSObject;
        menuBox: CSSObject;
      };
      navigationRail: {
        container?: CSSObject;
        logoBox: CSSObject;
        iconButton: CSSObject;
        activeBackgroundColor: string;
        inactiveBackgroundColor: string;
        listItem: CSSObject;
        text: CSSObject;
        notificationIcon: CSSObject;
        avatar: CSSObject;
        bottomSection: CSSObject;
        logoMarginBottom: CSSObject;
        iconButtonGray: CSSObject;
      };
      navigationDrawer: {
        box?: CSSObject;
        paper: CSSObject;
        listItem: CSSObject;
        subListItem: CSSObject;
        subListItemInnerPadding: CSSObject;
        text: CSSObject;
        headingStyle: CSSObject;
      };
      passwordInput: {
        heading: CSSObject;
        formControl: CSSObject;
        button: CSSObject;
        divider: CSSObject;
        forgottenText: CSSObject;
        modal: CSSObject;
      };
      verifyCode: {
        textAlign: string;
        titleStyle: CSSObject;
        descriptionStyle: CSSObject;
        enterCode: CSSObject;
        inputContainer: CSSObject;
        inputField: CSSObject;
        statusMessageStyle: CSSObject;
        closeButton: CSSObject;
      };
    };
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    loginHeaderTextActive: true;
    loginHeaderTextInactive: true;
    loginH1Text: true;
    loginH4Text: true;
    loginCreateAccountText: true;
    signupH2Text: true;
    loginParagraphText: true;
    drawerHeading: true;
    drawerText: true;
  }
}

const baseLoginHeaderText: CSSProperties = {
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.938rem",
  color: "#0F0D21",
};

const baseEuclidFlexText: CSSProperties = {
  fontFamily: "Euclid Flex",
  fontWeight: 500,
};

const baseDegularDisplayText: CSSProperties = {
  fontFamily: "degular-display",
  fontWeight: 500,
};

const basePurple = "#BBBAE8";

/*
  This doesn't seem too brilliant creating two themes,
  however it's a response from the guys at MUI. This
  is solely a typescript issue. Once this class is split, 
  I'll provide a more elegant solution.

*/
export let provideTheme: Theme = createTheme();
provideTheme = createTheme({
  custom: {
    grayDisclamerText: "#93929B",
    greenConfirmText: "#007865",
    grayLinkText: "#A9A9A9",
    basePurple,
    contentWidth: {
      menuClosedWidth: "90px",
      menuOpenWidth: "330px",
    },
    authWrapper: {
      box: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      },
      circularProgress: {
        color: "#8E8ED1",
      },
    },
    emailPhoneInput: {
      provideButtonOutlined: {
        position: "relative",
        width: "100%",
        mb: 3,
      },
      iconBox: {
        position: "absolute",
        left: "10px",
        display: "flex",
        alignItems: "center",
      },
      typography: {
        mt: 6,
      },
      formControl: {
        mt: 3,
      },
      outlinedInput: {
        fontFamily: "Roboto",
      },
      continueButton: {
        width: "100%",
        [provideTheme.breakpoints.up("sm")]: {
          width: "303px",
        },
        mt: 3,
      },
    },
    pillNavigation: {
      box: {
        display: "flex",
        marginTop: -1,
        marginLeft: "40px",
        [provideTheme.breakpoints.up("sm")]: {
          marginLeft: "96px",
        },
      },
      button: {
        width: "173px",
        height: "30px",
        borderRadius: "15px",
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: "0.688rem",
        lineHeight: 1,
        marginLeft: "-30px",
        transition: "none",
      },
      activeButton: {
        backgroundColor: "#8E8ED1",
        color: "#FFFFFF",
        zIndex: 1,
      },
      inactiveButton: {
        backgroundColor: "#E4E3F6",
        color: "#000000",
        zIndex: 0,
      },
    },
    signUpScreen: {
      container: {
        mt: 6,
        pl: { xs: 2, sm: 2, md: 35 },
        pr: 2,
        position: "relative",
      },
      subtitle: {
        mt: 1,
        mb: 4,
      },
      dropShadowBox: {
        mt: 2,
        mb: 4,
        width: "100%",
        [provideTheme.breakpoints.up("md")]: {
          width: "603px",
        },
      },
      createAccountText: {
        component: "p",
        variant: "loginCreateAccountText",
        mb: 4,
        ml: 4,
      },
      loginWave: {
        position: "fixed",
        display: "none",
        [provideTheme.breakpoints.up("md")]: {
          display: "block",
        },
        bottom: 0,
        right: 0,
      },
    },
    loginScreen: {
      loginWave: {
        position: "fixed",
        display: "none",
        [provideTheme.breakpoints.up("md")]: {
          display: "block",
        },
        bottom: 0,
        right: 0,
      },
      container: {
        mt: 6,
        pl: 2,
        [provideTheme.breakpoints.up("md")]: {
          pl: 35,
        },
        pr: 2,
        position: "relative",
      },
      loginComponentBox: {
        mt: 2,
        mb: 4,
        width: "100%",
        [provideTheme.breakpoints.up("md")]: {
          width: "540px",
        },
      },
      createAccountText: {
        mb: 8,
        ml: 4,
        disclaimer: {
          color: "#",
        },
      },
    },
    signUpBorrower: {
      flexContainer: {
        display: "flex",
        gap: 2,
        mt: 1,
        flexDirection: "column",
        [provideTheme.breakpoints.up("sm")]: {
          flexDirection: "row",
        },
      },
      inputMargin: {
        mt: 3,
      },
      formInput: {
        fontFamily: "Roboto",
        fontSize: "15px",
        borderRadius: "27px",
      },
      checkBox: {
        color: "black",
        "&.Mui-checked": {
          color: "black",
        },
      },
      labelTypography: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "13px",
        color: "#333",
      },
      radioButton: {
        "&.Mui-checked": {
          color: "black",
        },
      },
      radioGroup: {
        display: "flex",
        gap: 1,
        borderLeft: "1px solid #000",
        paddingLeft: 1.5,
      },
      submitButton: {
        width: "100%",
        [provideTheme.breakpoints.up("sm")]: {
          width: "260px",
        },
        mt: 3,
      },
      divider: {
        width: "100%",
        mt: 4,
        mb: 3,
        borderColor: "black",
      },
      registerText: {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "14px",
        color: "#333",
        textAlign: "left",
        mb: 1,
      },
      iconContainer: {
        display: "flex",
        alignItems: "flex-end",
      },
      iconMargin: {
        marginRight: 2,
      },
    },
    dropShadowBox: {
      p: 4,
      backgroundColor: "#fff",
      boxShadow:
        "rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;",
      border: 1,
      borderRadius: 0.8,
      borderColor: "#e0def8",
    },
    dismissibleBox: {
      bgcolor: "grey.200",
      py: 1,
      px: 2,
      borderRadius: 27,
      mb: 3,
    },
    loginSignupToolbar: {
      appBar: {
        position: "relative",
      },
      container: {
        display: "flex",
        height: 115,
        justifyContent: "flex-start",
        alignItems: "center",
        flexShrink: 0,
        gap: 0,
        [provideTheme.breakpoints.up("md")]: {
          gap: 3,
        },
        [provideTheme.breakpoints.up("lg")]: {
          gap: 10,
        },
      },
      pillContainer: {
        display: "flex",
        height: 115,
        justifyContent: "flex-start",
        alignItems: "center",
        flexShrink: 0,
        gap: 0,
      },
      logoBox: {
        display: "none",
        [provideTheme.breakpoints.up("md")]: {
          display: "flex",
        },
      },
      mobileLogoBox: {
        display: "flex",
        [provideTheme.breakpoints.up("md")]: {
          display: "none",
        },
      },
      menuBox: {
        display: "none",
        [provideTheme.breakpoints.up("md")]: {
          display: "flex",
          gap: 2.5,
        },
        [provideTheme.breakpoints.up("lg")]: {
          gap: 10,
        },
      },
    },
    navigationRail: {
      container: {
        display: "flex",
        flexDirection: "column",
        width: 80,
        height: "100vh",
        borderRight: "2px solid #E4E3F6",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        paddingTop: 2,
        position: "relative",
        zIndex: 2,
        overflowY: "auto",
      },
      logoBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: 1,
      },
      iconButton: {
        color: "#000",
        padding: "12px",
        borderRadius: "25px",
        width: "56px",
        height: "32px",
        transition: "background-color 0.5s ease",
        "&:hover": {
          backgroundColor: "#ddd",
          borderRadius: "25px",
        },
        "&.Mui-selected": {
          backgroundColor: basePurple,
          "&:hover": {
            backgroundColor: basePurple,
          },
        },
      },
      activeBackgroundColor: basePurple,
      inactiveBackgroundColor: "transparent",
      listItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 0.5,
        cursor: "pointer",
      },
      text: {
        fontSize: 12,
        color: "#000",
        mt: -0.8,
      },
      notificationIcon: {
        mb: 1,
        color: "gray",
        padding: "4px",
      },
      avatar: {
        bgcolor: "gray",
        width: 28,
        height: 28,
        fontSize: "0.75rem",
        cursor: "pointer",
      },
      bottomSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 5,
      },
      logoMarginBottom: {
        mb: 2.5,
      },
      iconButtonGray: {
        color: "gray",
      },
    },
    navigationDrawer: {
      box: {
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        backgroundColor: "#E4E3F6",
        zIndex: 1,
      },
      paper: {
        "& .MuiDrawer-paper": {
          position: "absolute",
          zIndex: 2,
          width: "320px",
          paddingLeft: "90px",
          paddingRight: "10px",
          boxSizing: "border-box",
          backgroundColor: "#E4E3F6",
          borderRadius: "25px",
          border: "none",
        },
      },
      listItem: {
        borderRadius: "25px",
        "&:hover": {
          backgroundColor: "rgba(25, 118, 210, 0.08)",
          borderRadius: "25px",
        },
        "&.Mui-selected": {
          backgroundColor: basePurple,
          "&:hover": {
            backgroundColor: basePurple,
          },
        },
        padding: "5px 15px",
      },
      subListItem: {
        borderRadius: "25px",
        padding: "5px 15px",
        "&:hover": {
          backgroundColor: "rgba(25, 118, 210, 0.08)",
          borderRadius: "25px",
        },
        "&.Mui-selected": {
          backgroundColor: basePurple,
          "&:hover": {
            backgroundColor: basePurple,
          },
        },
        "&:first-of-type": {
          marginTop: "1px",
        },
      },
      subListItemInnerPadding: {
        paddingLeft: "30px",
      },
      text: {
        display: "flex",
        alignItems: "center",
        margin: "5px 0",
        paddingLeft: "16px",
      },
      headingStyle: {
        padding: "10px 0 8px 16px",
      },
    },
    passwordInput: {
      heading: {
        fontSize: "1.25rem",
        fontWeight: 500,
        marginBottom: "24px",
      },
      formControl: {
        width: "100%",
      },
      button: {
        marginTop: "24px",
        width: "100%",
        [provideTheme.breakpoints.up("sm")]: {
          width: "303px",
        },
      },
      divider: {
        marginTop: "40px",
      },
      forgottenText: {
        marginTop: "32px",
        textAlign: "left",
      },
      modal: {
        width: "409px",
        height: "auto",
      },
    },
    verifyCode: {
      textAlign: "left",
      titleStyle: {
        marginTop: 1,
      },
      descriptionStyle: {
        marginTop: 1,
      },
      enterCode: {
        marginLeft: 1,
        textAlign: "left",
        marginTop: 3,
      },
      inputContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginY: 1,
        paddingX: 1,
      },
      inputField: {
        width: 43,
        "& .MuiOutlinedInput-root": {
          borderRadius: "15px",
        },
      },
      statusMessageStyle: {
        marginLeft: 1,
        textAlign: "left",
        marginY: 2,
        color: "#d32f2f",
      },
      closeButton: {
        marginLeft: 1,
        width: "80px",
        textAlign: "left",
      },
    },
  },
  shape: {
    borderRadius: 27,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          boxShadow: "none",
          borderBottom: "1px solid #ccc",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0 0 0 20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 27,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.icon-button": {
            color: "#000",
            padding: "12px",
            borderRadius: "12px",
            width: "56px",
            height: "32px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "51px",
          fontSize: "1rem",
          boxShadow: "none",
          borderRadius: 27,
          "&.MuiButton-contained": {
            backgroundColor: "black",
            color: "#fff",
            "&:disabled": {
              opacity: 0.25,
            },
          },
          "&.MuiButton-outlined": {
            backgroundColor: "white",
            color: "#000",
            borderColor: "#ccc",
            "&:disabled": {
              opacity: 0.5,
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: "Roboto",
          fontWeight: 600,
          fontSize: "1rem",
        },
        secondary: {
          fontFamily: "Roboto",
          fontWeight: 400,
          fontSize: "0.875rem",
        },
      },
    },
  },
  typography: {
    loginHeaderTextActive: baseLoginHeaderText,
    loginHeaderTextInactive: {
      ...baseLoginHeaderText,
      color: "#D3D3D3",
    },
    loginH1Text: {
      ...baseDegularDisplayText,
      fontSize: "3rem",
      color: "#8E8ED1",
    },
    loginH4Text: {
      ...baseEuclidFlexText,
      fontSize: "1.5rem",
    },
    loginCreateAccountText: {
      ...baseLoginHeaderText,
      fontWeight: 400,
      fontSize: "0.875rem",
    },
    loginParagraphText: {
      fontFamily: "Euclid Flex",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "28px",
    },
    signupH2Text: {
      fontFamily: "Euclid Flex",
      fontSize: "20px",
      fontWeight: 500,
      color: "#000000",
    },
    drawerHeading: {
      fontFamily: "Euclid Flex",
      fontWeight: 500,
      fontSize: "1rem",
    },
    drawerText: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "0.875rem",
    },
  },
});
