export interface MenuItem {
  id: number;
  variant: "loginHeaderTextActive" | "loginHeaderTextInactive";
  text: string;
}

export interface PillItem {
  id: number;
  label: string;
  type: string;
}

export const loginData: MenuItem[] = [
  { id: 1, variant: "loginHeaderTextActive", text: "1. ENQUIRY DETAILS" },
  { id: 2, variant: "loginHeaderTextInactive", text: "2. LENDER MATCHES" },
  { id: 3, variant: "loginHeaderTextInactive", text: "3. BESPOKE TERMS" },
  { id: 4, variant: "loginHeaderTextInactive", text: "4. APPLICATIONS" },
];

export const pillData: PillItem[] = [
  { id: 1, label: "CREATE BORROWER", type: "borrower" },
  { id: 2, label: "CREATE BROKER", type: "broker" },
];
