import React, { useState } from "react";
import { Box, Typography, IconButton, Collapse } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const DropdownInfoBox = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      width={{ sm: "100%", md: "540px" }}
      boxSizing="border-box"
      borderRadius="27px"
      bgcolor="#E1F5FC"
      padding="20px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" alignItems="center">
          <InfoIcon sx={{ color: "blue", marginRight: "8px" }} />
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              fontFamily: "Euclid Flex",
              fontWeight: 500,
            }}
          >
            Is the Provide service Free for me?
          </Typography>
        </Box>
        <IconButton onClick={handleToggle} aria-label="toggle dropdown">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Typography
          variant="body2"
          sx={{
            fontSize: "16px",
            fontFamily: "Euclid Flex",
            fontWeight: 400,
            color: "#333",
            marginTop: "10px",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Typography>
      </Collapse>
    </Box>
  );
};

export default DropdownInfoBox;
