import React, { useState } from "react";
import EmailPhoneInput from "./EmailPhoneInput";
import PasswordInput from "./PasswordInput";
import DropShadowBox from "../DropShadowBox/DropShadowBox";

enum Step {
  Login = "login",
  Password = "password",
}

const LoginComponent: React.FC = () => {
  const [step, setStep] = useState<Step>(Step.Login);
  const [emailOrPhone, setEmailOrPhone] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailOrPhone(e.target.value);
  };

  const handleContinue = () => {
    setStep(Step.Password);
  };

  const handleBack = () => {
    setEmailOrPhone("");
    setStep(Step.Login);
  };

  return (
    <DropShadowBox>
      {step === Step.Login ? (
        <EmailPhoneInput
          emailOrPhone={emailOrPhone}
          onChange={handleChange}
          onContinue={handleContinue}
        />
      ) : (
        <PasswordInput emailOrPhone={emailOrPhone} onBack={handleBack} />
      )}
    </DropShadowBox>
  );
};

export default LoginComponent;
