import React from "react";
import { Typography, SxProps, Theme } from "@mui/material";

interface PlainTextButtonProps {
  onClick: () => void;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

const PlainTextButton: React.FC<PlainTextButtonProps> = (props) => {
  const { onClick, sx, children } = props;

  return (
    <Typography
      variant="body2"
      onClick={onClick}
      sx={{ cursor: "pointer", userSelect: "none", fontWeight: 500, ...sx }}
    >
      {children}
    </Typography>
  );
};

export default PlainTextButton;
