import React from "react";
import { Box, Container, Typography, useTheme } from "@mui/material";
import LoginWave from "../components/Icons/LoginWave/LoginWave";
import LoginComponent from "../components/LoginComponent/LoginComponent";
import { Link } from "react-router-dom";
import DisclaimerText from "../components/DisclamerText/DisclamerText";

const LoginScreen: React.FC = () => {
  const theme = useTheme();
  const { loginWave, container, loginComponentBox, createAccountText } =
    theme.custom.loginScreen;
  const { grayLinkText } = theme.custom;

  const defaultTheme = JSON.stringify({
    name: "Provide",
    primary_color: null,
    secondary_color: null,
    tertiary_color: null,
    logo: null,
    t_name: "Provide Finance",
  });
  const companyTheme = JSON.parse(
    localStorage.getItem("PF-CONF") || defaultTheme,
  );

  return (
    <>
      <Box sx={loginWave}>
        <LoginWave />
      </Box>
      <Container>
        <Box sx={container}>
          <Typography component="h1" variant="loginH1Text">
            Log in to {companyTheme.name}
          </Typography>
          <Box sx={loginComponentBox}>
            <LoginComponent />
          </Box>
          <Typography
            component="p"
            variant="loginCreateAccountText"
            sx={createAccountText}
          >
            <span style={{ color: grayLinkText }}>Don’t have an account?</span>{" "}
            <span>
              <Link to="./sign-up?user=borrower" style={{ fontWeight: "bold" }}>
                Create a free account now
              </Link>
            </span>
          </Typography>
          <DisclaimerText />
        </Box>
      </Container>
    </>
  );
};

export default LoginScreen;
