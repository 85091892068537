import React from "react";

interface ProvideLogoProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const ProvideLogo: React.FC<ProvideLogoProps> = (props) => {
  const { width = 212, height = 44, fillColor = "#000000" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 212 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ shapeRendering: "auto" }}
    >
      <path
        d="M42.4858 21.5946L37.9591 8.69634C34.1415 -2.17301 18.9967 -3.08396 13.8858 7.26779L7.83618 19.5243C4.89482 25.4869 -10.1666 48.3436 11.4868 43.2712L29.8025 38.9649C46.5745 35.0519 44.6762 27.8471 42.4858 21.5946ZM18.4543 23.2923C16.0136 23.2923 14.0527 21.3255 14.0527 18.9239C14.0527 16.5223 16.0344 14.5554 18.4543 14.5554C20.8741 14.5554 22.8559 16.5223 22.8559 18.9239C22.8559 21.3255 20.895 23.2923 18.4543 23.2923ZM31.0124 23.2923C28.5717 23.2923 26.6108 21.3255 26.6108 18.9239C26.6108 16.5223 28.5926 14.5554 31.0124 14.5554C33.4323 14.5554 35.414 16.5223 35.414 18.9239C35.414 21.3255 33.4323 23.2923 31.0124 23.2923Z"
        fill={fillColor}
      />
      <path
        d="M79.9102 28.7994C75.8215 28.7994 73.2765 26.9982 72.1291 22.6919H72.0248V34.0581H65.1825V7.78538H72.0248V13.2925H72.1291C73.2765 8.98619 75.9675 7.22639 80.0562 7.22639C85.7512 7.22639 88.6925 11.2222 88.6925 17.868C88.6925 24.7623 85.6469 28.7994 79.9102 28.7994ZM81.9545 17.9094C81.9545 14.514 80.4525 12.5265 77.0105 12.5265C73.8606 12.5265 72.0248 14.3691 72.0248 17.7438V18.1371C72.0248 21.4497 73.8814 23.5201 77.0105 23.5201C80.4108 23.4993 81.9545 21.429 81.9545 17.9094Z"
        fill={fillColor}
      />
      <path
        d="M90.4865 28.199V7.78537H97.3288V15.073H97.4331C98.5804 8.86195 100.52 7.78537 103.816 7.78537H104.964V14.4933H102.377C98.5387 14.4933 97.3288 15.4043 97.3288 18.4477V28.1783H90.4865V28.199Z"
        fill={fillColor}
      />
      <path
        d="M105.464 17.9922C105.464 11.1808 110.262 7.22639 117.731 7.22639C125.157 7.22639 129.955 11.1808 129.955 17.9922C129.955 24.7002 125.324 28.7994 117.731 28.7994C110.096 28.7994 105.464 24.7209 105.464 17.9922ZM112.307 17.9508C112.307 21.2634 114.205 23.4165 117.689 23.4165C121.235 23.4165 123.175 21.2634 123.175 17.9508C123.175 14.6797 121.173 12.5679 117.731 12.5679C114.247 12.5679 112.307 14.6797 112.307 17.9508Z"
        fill={fillColor}
      />
      <path
        d="M137.694 28.1991L128.87 7.7854H136.255L139.196 15.7977C140.051 18.0336 140.886 20.2696 141.637 22.4642H141.845C142.638 20.311 143.452 18.0336 144.286 15.7977L147.332 7.7854H154.424L145.621 28.1991H137.694Z"
        fill={fillColor}
      />
      <path
        d="M154.717 5.63221V1.80206H161.809V5.63221H154.717ZM154.863 28.199V7.78538H161.705V28.199H154.863V28.199Z"
        fill={fillColor}
      />
      <path
        d="M180.354 22.6919H180.208C179.061 26.9982 176.37 28.7994 172.323 28.7994C166.586 28.7994 163.645 24.7416 163.645 18.1164C163.645 11.2222 166.732 7.18498 172.427 7.22638C176.516 7.18498 179.123 8.98618 180.271 13.3339H180.375V1.80206H187.134V28.199H180.354V22.6919ZM180.354 18.2614V17.868C180.354 14.5554 178.456 12.4851 175.368 12.4851C171.926 12.4851 170.383 14.5554 170.383 18.1164C170.383 21.4704 171.885 23.4993 175.368 23.4993C178.456 23.4993 180.354 21.6153 180.354 18.2614Z"
        fill={fillColor}
      />
      <path
        d="M188.949 18.427C188.949 11.3671 193.496 7.22639 201.173 7.22639C208.454 7.22639 212 11.2222 212 16.7914C212 17.7024 212 18.3856 211.896 19.5036H195.729C196.188 22.4228 197.982 23.7271 201.173 23.7271C204.323 23.7271 205.366 22.4021 205.909 20.8079L211.791 22.2364C210.644 25.9424 207.557 28.7788 201.069 28.7788C193.935 28.7581 188.949 25.2177 188.949 18.427ZM195.77 16.191H205.742C205.533 13.4789 203.989 12.0917 200.944 12.0917C197.982 12.0917 196.229 13.2925 195.77 16.191Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ProvideLogo;
