import React from "react";
import { Box, BoxProps, useTheme } from "@mui/material";

type StyledBoxProps = BoxProps;

const DropShadowBox: React.FC<StyledBoxProps> = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        ...theme.custom.dropShadowBox,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default DropShadowBox;
