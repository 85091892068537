import React from "react";
import { Button, Box, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { setUserType } from "../../store/slices/signupSlice";
import { RootState } from "../../store/store";
import { useLocation, useNavigate } from "react-router-dom";

interface NavigationButton {
  id: number;
  label: string;
  type: string;
}

interface PillNavigationProps {
  buttons: NavigationButton[];
}

const PillNavigation: React.FC<PillNavigationProps> = ({ buttons }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const userType = useAppSelector((state: RootState) => state.signup.userType);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    box: boxStyle,
    button: buttonStyle,
    activeButton: activeButtonStyle,
    inactiveButton: inactiveButtonStyle,
  } = theme.custom.pillNavigation;

  const handleClick = (button: NavigationButton) => {
    const userTypeQueryString = `${location.pathname}?user=${button.type}`;
    navigate(userTypeQueryString);
    dispatch(setUserType(button.type));
  };

  return (
    <Box sx={boxStyle}>
      {buttons.map((button) => {
        const isActive = userType === button.type;
        const buttonActiveStyles = isActive
          ? activeButtonStyle
          : inactiveButtonStyle;

        return (
          <Button
            key={button.id}
            sx={{
              ...buttonStyle,
              ...buttonActiveStyles,
            }}
            onClick={() => handleClick(button)}
          >
            {button.label}
          </Button>
        );
      })}
    </Box>
  );
};

export default PillNavigation;
