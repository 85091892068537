import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  Radio,
  RadioGroup,
  Divider,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import ProvideButton from "../Buttons/ProvideButton";
import useValidateInput from "../../hooks/useValidateInput";
import AppleIcon from "../Icons/AppleIcon/AppleIcon";
import GoogleIcon from "../Icons/GoogleIcon/GoogleIcon";
import FacebookIcon from "../Icons/FacebookIcon/FacebookIcon";
import useRegister from "../../hooks/useRegister";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useRedux";
import { login } from "../../store/slices/authSlice";

const SignUpBorrower: React.FC = () => {
  const { errors, validateField, validatePassword } = useValidateInput();
  const { handleRegister, registering, registerError } = useRegister();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [areInputsValidated, setAreInputsValidated] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const {
    flexContainer,
    inputMargin,
    formInput,
    checkBox,
    labelTypography,
    radioButton,
    radioGroup,
    submitButton,
    divider,
    registerText,
    iconContainer,
    iconMargin,
  } = theme.custom.signUpBorrower;

  const handleSubmit = async () => {
    const result = await handleRegister(
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.password,
      formData.confirmPassword,
    );

    if (result.success) {
      dispatch(login());
      navigate("/dashboard");
    }
  };

  const handleChange =
    (field: keyof typeof formData) =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      if (field === "password" || field === "confirmPassword") {
        validatePassword(field, value, formData.password);
      } else {
        validateField(field, value);
      }
      setFormData((prev) => ({ ...prev, [field]: value }));
    };

  useEffect(() => {
    const { email, password, confirmPassword, firstName, lastName } = formData;

    if (confirmPassword.trim()) {
      validatePassword("confirmPassword", confirmPassword, password);
    }

    const isFieldValid = (field: string, error: boolean) =>
      !error && field.trim() !== "";

    const emailValid = isFieldValid(email, errors.email?.error);
    const passwordsValid =
      isFieldValid(password, errors.password?.error) &&
      isFieldValid(confirmPassword, errors.confirmPassword?.error);
    const namesValid =
      isFieldValid(firstName, errors.firstName?.error) &&
      isFieldValid(lastName, errors.lastName?.error);

    setAreInputsValidated(emailValid && passwordsValid && namesValid);
  }, [errors, formData]);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);

  return (
    <>
      <Box sx={flexContainer}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="first-name"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange("firstName")}
            error={errors.firstName?.error}
            sx={formInput}
          />
          {errors.firstName?.error && (
            <FormHelperText error>{errors.firstName.helperText}</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="last-name"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange("lastName")}
            error={errors.lastName?.error}
            sx={formInput}
          />
          {errors.lastName?.error && (
            <FormHelperText error>{errors.lastName.helperText}</FormHelperText>
          )}
        </FormControl>
      </Box>

      <FormControl fullWidth variant="outlined" sx={inputMargin}>
        <OutlinedInput
          id="email"
          placeholder="Email Address"
          value={formData.email}
          onChange={handleChange("email")}
          error={errors.email?.error}
          sx={formInput}
        />
        {errors.email?.error && (
          <FormHelperText error>{errors.email.helperText}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth variant="outlined" sx={inputMargin}>
        <OutlinedInput id="phone" placeholder="Phone" sx={formInput} />
      </FormControl>

      <FormControl fullWidth variant="outlined" sx={inputMargin}>
        <OutlinedInput
          id="password"
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          value={formData.password}
          onChange={handleChange("password")}
          error={errors.password?.error}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePasswordVisibility}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          sx={formInput}
        />
        {errors.password?.error && (
          <FormHelperText error>{errors.password.helperText}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth variant="outlined" sx={inputMargin}>
        <OutlinedInput
          id="confirm-password"
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Confirm Password"
          value={formData.confirmPassword}
          onChange={handleChange("confirmPassword")}
          error={errors.confirmPassword?.error}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={toggleConfirmPasswordVisibility}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          sx={formInput}
        />
        {errors.confirmPassword?.error && (
          <FormHelperText error>
            {errors.confirmPassword.helperText}
          </FormHelperText>
        )}
      </FormControl>

      <Box display="flex" alignItems="center" mt={2}>
        <FormControlLabel
          control={<Checkbox sx={checkBox} />}
          label={
            <Typography sx={labelTypography}>
              I’m keen to hear about future offers
            </Typography>
          }
        />

        <RadioGroup row sx={radioGroup}>
          <FormControlLabel
            value="sms"
            control={<Radio sx={radioButton} />}
            label="SMS Verify"
            sx={{
              "& .MuiTypography-root": labelTypography,
            }}
          />
          <FormControlLabel
            value="email"
            control={<Radio sx={radioButton} />}
            label="Email Verify"
            sx={{
              "& .MuiTypography-root": labelTypography,
            }}
          />
        </RadioGroup>
      </Box>

      <ProvideButton
        onClick={handleSubmit}
        disabled={
          !!(
            errors.email?.error ||
            errors.phone?.error ||
            !areInputsValidated ||
            registering
          )
        }
        variant="contained"
        sx={submitButton}
      >
        {registering ? "Creating Account..." : "CREATE ACCOUNT"}
      </ProvideButton>

      {registerError && (
        <Typography color="error" mt={1}>
          {registerError.message}
        </Typography>
      )}

      <Divider sx={divider} />

      <Typography sx={registerText}>Or register via</Typography>
      <Box sx={iconContainer}>
        <Box sx={iconMargin}>
          <AppleIcon />
        </Box>
        <Box sx={iconMargin}>
          <GoogleIcon />
        </Box>
        <Box sx={iconMargin}>
          <FacebookIcon />
        </Box>
      </Box>
    </>
  );
};

export default SignUpBorrower;
